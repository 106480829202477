import React, { useEffect, useRef, useState } from "react";
//= Scripts
import navbarScrollEffect from "common/navbarScrollEffect";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import Navbar from "components/Navbars/AppNav";
import Form from "components/Contact/Form";
import Footer from "components/App/Footer";
import KakaoMap from "components/Contact/Kakaomap";

const Contact = () => {
  const navbarRef = useRef(null);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 991);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <MainLayout>
      <Navbar navbarRef={navbarRef} section={"contact"} isMobile={isMobile} />
      <main className="contact-page style-5">
        <Form style="5" isMobile={isMobile} />
        <KakaoMap />
      </main>
      <Footer noWave active={"contact"} isMobile={isMobile} />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
      <title>핑프-핑거프린세스</title>
      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default Contact;
