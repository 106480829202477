import React, { useEffect } from "react";

const KakaoMap = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "//dapi.kakao.com/v2/maps/sdk.js?appkey=3efd590124e486f11e17caf30a4e0c5a&autoload=false";
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    const onLoadKakaoMap = () => {
      if (window.kakao && window.kakao.maps) {
        window.kakao.maps.load(() => {
          const container = document.getElementById("map");
          const options = {
            center: new window.kakao.maps.LatLng(37.50675, 127.0526),
            level: 4,
          };

          const map = new window.kakao.maps.Map(container, options);

          const markerPosition = new window.kakao.maps.LatLng(
            37.50675,
            127.0526
          );
          const marker = new window.kakao.maps.Marker({
            position: markerPosition,
          });

          marker.setMap(map);
        });
      } else {
        console.error("Kakao maps library not available.");
      }
    };

    script.onload = onLoadKakaoMap;

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "500px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h3>🏠 주소</h3>
        <span>서울특별시 강남구 테헤란로 69길 14 삼성빌딩</span>
      </div>
      <div id="map" style={{ width: "100%", height: "400px" }} />
    </div>
  );
};

export default KakaoMap;
