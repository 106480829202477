import React, { useState } from "react";
import axios from "axios";

const Form = ({ style = "4", rtl, isMobile }) => {
  const [formData, setFormdata] = useState({
    name: "",
    email: "",
    phone: "",
    website: "",
    option: "",
    message: "",
  });

  const handleFormChange = (e) => {
    setFormdata((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formValues = new FormData();

    formValues.append("name", formData.name);
    formValues.append("email", formData.email);
    formValues.append("phone", formData.phone);
    formValues.append("website", formData.website);
    formValues.append("option", formData.option);
    formValues.append("message", formData.message);

    const res = await axios
      .post("https://api.finger-princess.com/mail/contract", {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        link: formData.website,
        category: formData.option,
        text: formData.message,
      })
      .catch((err) => alert(err.message));

    if (!res) return;

    alert("Form submitted successfully.");
  };

  return (
    <section
      className={`contact section-padding pt-${
        style === "4" ? "0" : "50"
      } style-6`}
    >
      {style === "5" && (
        <>
          <div className="section-head text-center mb-100 style-5">
            <h2 className="mb-20">
              <span>문의하기</span>
            </h2>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span>
                문의하신 내용을 확인하고 24시간 내에 다시 연락드리겠습니다.
              </span>
              <span>감사합니다.</span>
            </div>
          </div>
          <div className="text-center mb-100">
            <h2
              className="ltspc-20 text-uppercase fs-1 lh-1 mb-50 mt-30"
              style={{ color: "#fd7f96" }}
            >
              {"02) 508-5591"}
            </h2>
            <h4 className="fw-normal mb-20 color-000">{"votrecs@votre.kr"}</h4>
            <h4 className="fw-normal mb-10 color-000">
              {"서울특별시 강남구 테헤란로 69길 14 보트르내일"}
            </h4>
          </div>
        </>
      )}
      <div className="container">
        <div className="content">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <form
                action="contact.php"
                className="form"
                method="post"
                onSubmit={handleFormSubmit}
              >
                <p className="text-center text-danger fs-12px mb-30">
                  {"* 붙은 곳은 필수 입력란 입니다."}
                </p>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group mb-20">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder={"이름"}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-20">
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder={"이메일 주소 *"}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-20">
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        placeholder={"연락처 (선택 사항)"}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-20">
                      <input
                        type="text"
                        name="website"
                        className="form-control"
                        placeholder={"첨부 링크 (선택 사항)"}
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-20">
                      <select
                        className="form-select"
                        defaultValue={"How can we help you?"}
                        name="option"
                        onChange={handleFormChange}
                      >
                        <option value="how can we help">
                          {"어떤 내용으로 문의하시나요?"}
                        </option>
                        <option value="핑프">{"핑프"}</option>
                        <option value="핑프메이커">{"핑프메이커"}</option>
                        <option value="협업 제안">{"협업 제안"}</option>
                        <option value="기타 문의">{"기타 문의"}</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <textarea
                        rows="10"
                        name="message"
                        className="form-control"
                        placeholder={"문의 내용을 작성해주세요."}
                        onChange={handleFormChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-12 text-center">
                    <div className="form-check d-inline-flex mt-30 mb-30">
                      <input
                        className="form-check-input me-2 mt-0"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label small"
                        htmlFor="flexCheckDefault"
                      >
                        {"위 내용으로 , 문의하시는데 동의하시나요?"}
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-12 text-center">
                    <input
                      type="submit"
                      value={"문의 전송하기"}
                      className="btn rounded-pill blue5-3Dbutn hover-blue2 sm-butn fw-bold text-light"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <img
            src="/assets/img/icons/contact_a.png"
            alt=""
            className="contact_a"
          />
          <img
            src="/assets/img/icons/contact_message.png"
            alt=""
            className="contact_message"
          />
        </div>
      </div>
    </section>
  );
};

export default Form;
